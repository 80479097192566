/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

@font-face {
	font-family: "Roboto";
	src: url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Roboto-Thin";
	src: url(./assets/fonts/Roboto-Thin.ttf) format("truetype");
}

html,
body {
	background-color: #f4f7fc;
	padding: 0;
	margin: 0;
	font-size: 13px;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	height: 100vh;
  width: 100vw;
}

#app {
	height: 100vh;
  width: 100vw;
	display: grid;
	grid-template-columns: 100px auto;
	grid-template-rows: 100px auto;
}

#header {
	vertical-align: middle;
	display: table-cell;
	grid-column: 2 / 2;
	grid-row: 1 / 1;
}

#logo {
	background-image: url(./assets/images/logo.png);
	background-size: 50%;
	background-repeat: no-repeat;
	background-position-x: 20px;
	background-position-y: center;
	width: 200px;
	float: left;
	height: 100%;
}

#header-profile {
	margin-left: auto;
	margin-right: 0;
}

#main-menu {
	grid-column: 1 / 1;
	grid-row: 1 / 3;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 4px 9px 0px rgba(222, 222, 222, 0.75);
	box-shadow: 0px 4px 9px 0px rgba(222, 222, 222, 0.75);
}

#main-menu a,
#main-menu a:visited,
#main-menu a.active {
	display: block;
	text-decoration: none;
	color: #72809d;
	font-size: 2rem;
	text-align: center;
	padding: 10px;
}

#main-menu .name {
	font-size: 1rem;
}

#main-menu a svg[data-icon] {
	vertical-align: middle;
}

#main-menu a.active {
	color: #17bebb;
	background-color: #edfdfc;
	/* background-color: #17bebb; */
	/* -webkit-border-radius: 2rem;
	-moz-border-radius: 2rem;
	border-radius: 2rem; */
	border-left: 3px solid #17bebb;
}

#main-menu a.active svg[data-icon] {
	color: #17bebb;
}

#main-menu a:hover {
	color: #17bebb;
}

#page-container {
	grid-column: 2 / 2;
	grid-row: 2 / 2;
}

#page {
	padding: 40px;
	max-width: 90rem;
	margin-left: auto;
	margin-right: auto;
}

.page-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
}

.page-head-title-content > h1 {
	font-weight: 100;
	font-size: 4rem;
	font-family: "Roboto-Thin";
	color: #40536a;
}

.page-head-title-content > h2 {
	font-size: 1.5rem;
	font-family: "Roboto";
	color: #40536a;
	margin-bottom: 2rem;
}

.page-head .fi {
	padding: 0;
	margin: 0;
	border-radius: 5px;
	margin-left: 5px;
}

.page-head-content {

}

.paper-root {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 4px 9px 0px rgba(222, 222, 222, 0.75);
	box-shadow: 0px 4px 9px 0px rgba(222, 222, 222, 0.75);

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.paper-root table thead th:first-child {
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
	border-radius: 5px 0 0 0;
}

.paper-root table thead th:last-child {
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
	border-radius: 0 5px 0 0;
}

.paper-root table tbody tr:last-child td:last-child {
	-webkit-border-radius: 0 0 5px 0;
	-moz-border-radius: 0 0 5px 0;
	border-radius: 0 0 5px 0;
}

.paper-root table tbody tr:last-child td:first-child {
	-webkit-border-radius: 0 0 0 5px;
	-moz-border-radius: 0 0 0 5px;
	border-radius: 0 0 0 5px;
}

.show-on-hover {
  visibility: hidden;
}

.breadcrumb-list {
	padding-inline-start: 0;
}

table {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
	border: 1px solid #e5e7eb;
}

table thead {
	background-color: rgba(249, 250, 251, 1);
}

table thead th {
	text-align: left;
	text-transform: uppercase;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 12px;
	font-weight: 100;
	font-size: 1rem;
}

table tbody td {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 1rem;
}

table tbody tr {
	border-bottom: 1px solid #e5e7eb;
}

table tbody tr:nth-child(even) {
	/* background-color: #F9FAFB; */
}

table tbody tr td:last-child {
  text-align: right;
}

table tbody tr.clickable:hover {
	background-color: #f4f7fc;
}

table tbody tr:hover .show-on-hover {
	visibility: visible!important;
}

.clickable {
	cursor: pointer;
}

.profile-container {
	margin: 20px;
	display: block;
	float: right;
}

.menu-container {
	position: relative;
}

.menu {
	background: #ffffff;
	border-radius: 8px;
	position: absolute;
	top: 60px;
	right: 0;
	width: 300px;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: opacity 0.4s ease, transform 0.1s ease, visibility 0.1s;
}

.menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.menu li {
	border-bottom: 1px solid #dddddd;
}

.menu li a {
	text-decoration: none;
	color: #333333;
	padding: 15px 20px;
	display: block;
}

.menu-trigger {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px 6px;
	vertical-align: middle;
	transition: box-shadow 0.1s ease;
	background-color: transparent;
	border: none;
	font-size: 1rem;
	font-weight: 100;
}

.menu-trigger span {
	vertical-align: middle;
	font-size: 1rem;
	margin: 0 10px;
}

.menu-trigger img {
	border-radius: 90px;
}

.light-gray {
	color: rgba(0, 0, 0, 0.5);
}

.modal-root {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
}

.modal-container {
	margin: 15% auto;
	padding: 20px;
	width: min-content;

	background-color: #ffffff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.modal-container .close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.modal-container .close:hover,
.modal-container .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

button {
	border: none;
	font-size: 1rem;
	padding: 0.75rem;
	background-color: #17bebb;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	color: #ffffff;
}

button:hover,
button:focus {
	text-decoration: none;
	cursor: pointer;
}

button.icon {
  background-color: transparent;
  border: none;
  padding: 0;
  color: inherit;
font-size: 1.5rem;
}

button.icon:hover {
	background-color: #d2d2d2;
}

.ui.input input[type="text"] {
	padding: 0.5rem;
	border: 1px solid rgba(34, 36, 38, 0.15);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

input[type="text"]:focus {
	outline: none;
	border: 1px solid #17bebb;
}

.badge {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	color: #fff;
}

.badge.primary {
	background-color: #17bebb;
}

.badge.success {
	background-color: #67cf71;
}

.badge.danger {
	background-color: #f46850;
}

.badge.warning {
	background-color: #ffcc66;
	color: #000;
}

.badge.info {
	background-color: #4ca4e3;
}

.badge.secondary {
	background-color: #777777;
}












.portal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000000;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
  }
  
  .portal-overlay .confirm-dialog {
	z-index: 1000000000000111;
	padding: 16px;
	background-color: white;
	width: 400px;
	position: absolute;
	top: 200px;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
  }
  
  .portal-overlay .confirm-dialog__footer {
	display: flex;
	justify-content: flex-end;
	margin-top:20px;
  }
  
  .portal-overlay .confirm-dialog .btn {
	padding:6px 10px;
	margin: 0 10px;
  }

  .lt-small {
	  color: #72809d;
	  display: block;
  }

  .col-sm {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .container {
	width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .header-2 {
	font-weight: 100;
	font-size: 2rem;
	font-family: "Roboto-Thin";
	color: #40536a;
}